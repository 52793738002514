import User from '../../models/user';
import {
  SET_CHAT_GROUPS,
  // SET_INSTANTS,
  SET_NEW_CHAT_MESSAGE,
  SET_SELECTED_CHAT,
  SET_UNREAD_MESSAGE_COUNT,
  SET_USER,
  SET_INSTANT_SUGGESTIONS,
  SET_OWNER_ASSIGNMENTS,
  UserActionTypes,
  UserState,
  SET_MATCHING_ORDER,
  SET_MATCHING_OWNER,
  SET_UPDATE_CHAT_MESSAGE,
  SET_NEW_ORDER,
  SET_NEW_PRICE_QUOTES,
  SET_V2_INSTANTS,
  SET_INSTANT_RESERVE,
  SET_NEW_OWNER,
} from './types';

const initialState: UserState = {
  user: new User(),
  unreadMessageCount: {},
  chatGroups: [],
  instants: [],
  instantsV2: [],
  newOwner: {},
  newOrder: {},
  newPriceQuotes: [],
  newChatMessage: '',
  updateChatMessage: '',
  selectedChat: {},
  instantSuggestions: {},
  ownerAssignments: [],
  matchingOrder: {},
  matchingOwner: {},
  instantReserve: {},
};

export function userReducer(state = initialState, action: UserActionTypes): UserState {
  switch (action.type) {
    case SET_USER: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case SET_UNREAD_MESSAGE_COUNT: {
      return {
        ...state,
        unreadMessageCount: action.payload,
      };
    }
    case SET_CHAT_GROUPS: {
      return {
        ...state,
        chatGroups: action.payload,
      };
    }
    case SET_NEW_CHAT_MESSAGE: {
      return {
        ...state,
        newChatMessage: action.payload,
      };
    }
    case SET_UPDATE_CHAT_MESSAGE: {
      return {
        ...state,
        updateChatMessage: action.payload,
      };
    }
    case SET_SELECTED_CHAT: {
      return {
        ...state,
        selectedChat: action.payload,
      };
    }
    // case SET_INSTANTS: {
    //   return {
    //     ...state,
    //     instants: action.payload,
    //   };
    // }
    case SET_V2_INSTANTS: {
      return {
        ...state,
        instantsV2: action.payload,
      };
    }
    case SET_NEW_OWNER: {
      return {
        ...state,
        newOwner: action.payload,
      };
    }
    case SET_NEW_ORDER: {
      return {
        ...state,
        newOrder: action.payload,
      };
    }
    case SET_NEW_PRICE_QUOTES: {
      return {
        ...state,
        newPriceQuotes: action.payload,
      };
    }
    case SET_INSTANT_RESERVE: {
      return {
        ...state,
        instantReserve: action.payload,
      };
    }
    case SET_INSTANT_SUGGESTIONS: {
      return {
        ...state,
        instantSuggestions: action.payload,
      };
    }
    case SET_OWNER_ASSIGNMENTS: {
      return {
        ...state,
        ownerAssignments: action.payload,
      };
    }
    case SET_MATCHING_ORDER: {
      return {
        ...state,
        matchingOrder: action.payload,
      };
    }
    case SET_MATCHING_OWNER: {
      return {
        ...state,
        matchingOwner: action.payload,
      };
    }
    default:
      return state;
  }
}
