import React, { lazy, Suspense, useEffect } from 'react';
import { RouteComponentProps, Switch, Route, Redirect, useLocation } from 'react-router-dom';

import LoginRoute from './routes/LoginRoute';
import PrivateRoute from './routes/PrivateRoute';
import LogoutRoute from './routes/LogoutRoute';
import { useUser } from './store/hooks/hooks';
import Header from './components/Header';
import NotificationBar from './components/NotificationBar';
import CheckinCheckout from './components/CheckinCheckout';
import SelectedChat from './components/SelectedChat';
import CONSTANTS from './constants/constant';
import devtools from 'devtools-detect';

import './DesktopApp.scss';

const Home = lazy(() => import('./pages/Home'));
const UserProfile = lazy(() => import('./pages/UserProfile'));
const TaiXe = lazy(() => import('./pages/drivers/TaiXe'));
const Customer = lazy(() => import('./pages/customers/Customer'));
const CustomerAssign = lazy(() => import('./pages/customer-assign/CustomerAssign'));
const DriverAssign = lazy(() => import('./pages/driver-assign/DriverAssign'));
const Order = lazy(() => import('./pages/Order'));
const Owner = lazy(() => import('./pages/customers/Owner'));
const Transport = lazy(() => import('./pages/transports/Transport'));
const KiemSoatDonHang = lazy(() => import('./pages/kiem-soat-don-hang/KiemSoatDonHang'));
const PriceQuote = lazy(() => import('./pages/price-quote/PriceQuote'));
const Payment = lazy(() => import('./pages/payments/Payment'));
const InstantOrder = lazy(() => import('./pages/instants/InstantOrder'));
const InstantOrderV2 = lazy(() => import('./pages/instants/InstantOrderV2'));
const DriverInfo = lazy(() => import('./pages/DriverInfo'));
const AdminUserWeb = lazy(() => import('./pages/AdminUserWeb'));
const AdminUserTxe = lazy(() => import('./pages/AdminUserTxe'));
const AdminUserCh = lazy(() => import('./pages/AdminUserCh'));
const Chanh = lazy(() => import('./pages/Chanh'));
const ChanhDatHang = lazy(() => import('./pages/ChanhDatHang'));
const Receipt = lazy(() => import('./pages/Receipt'));
const WorkingTime = lazy(() => import('./pages/WorkingTime'));
const AdminWorkingTime = lazy(() => import('./pages/AdminWorkingTime'));
const WorkingStatistic = lazy(() => import('./pages/WorkingStatistic'));
const AppPhones = lazy(() => import('./pages/AppPhones'));
const Support = lazy(() => import('./pages/Support'));
const Privacy = lazy(() => import('./pages/Privacy'));
const Insurance = lazy(() => import('./pages/Insurance'));
const OwnerInsurance = lazy(() => import('./pages/OwnerInsurance'));
const InsuranceNearingExpiration = lazy(() => import('./pages/InsuranceNearingExpiration'));
const PhapChe = lazy(() => import('./pages/phap-che/PhapChe'));
const Files = lazy(() => import('./pages/files/Files'));
const CallTrackingStatistic = lazy(() => import('./pages/CallTrackingStatistic'));
const ManageInternalChat = lazy(() => import('./pages/manage-chat/ManageInternalChat'));
const OwnerAssignments = lazy(() => import('./pages/owner-assignments/OwnerAssignments'));
const KeToanChi = lazy(() => import('./pages/ke-toan-chi/KeToanChi'));
const KyQuy = lazy(() => import('./pages/ky-quy/KyQuy'));

window.addEventListener('orientationchange', function () {
  window.location.reload();
});

export default function DesktopApp() {
  const user = useUser();
  const location = useLocation();

  const renderHome = (props: RouteComponentProps) => {
    return <Home {...props} />;
  };

  const renderUserProfile = (props: RouteComponentProps) => {
    return <UserProfile {...props} />;
  };

  const redirectToPageNotFound = () => {
    return <Redirect to="/" />;
  };

  const renderTaiXePage = (props: RouteComponentProps) => {
    return <TaiXe {...props} />;
  };

  const renderCustomerPage = (props: RouteComponentProps) => {
    return <Customer {...props} />;
  };

  const renderCustomerAssignPage = () => {
    return <CustomerAssign />;
  };

  const renderDriverAssignPage = () => {
    return <DriverAssign />;
  };

  const renderManageInternalChatPage = () => {
    return <ManageInternalChat />;
  };

  const renderOrderPage = (props: RouteComponentProps) => {
    return <Order {...props} />;
  };

  const renderOwnerPage = (props: RouteComponentProps) => {
    return <Owner {...props} />;
  };

  const renderChanhPage = () => {
    return <Chanh />;
  };

  const renderChanhDatHangPage = () => {
    return <ChanhDatHang />;
  };

  const renderTransportPage = (props: RouteComponentProps) => {
    return <Transport {...props} />;
  };

  const renderKiemSoatDonHangPage = (props: RouteComponentProps) => {
    return <KiemSoatDonHang {...props} />;
  };

  const renderPriceQuote = (props: RouteComponentProps) => {
    return <PriceQuote {...props} />;
  };

  const renderPaymentPage = () => {
    return <Payment />;
  };

  const renderInstantOrderPage = (props: RouteComponentProps) => {
    return <InstantOrder {...props} />;
  };

  const renderNewInstantOrderPage = (props: RouteComponentProps) => {
    return <InstantOrderV2 {...props} />;
  };

  const renderDriverInfoPage = () => {
    return <DriverInfo />;
  };

  const renderReceiptPage = () => {
    return <Receipt />;
  };

  const renderAdminUserWebPage = () => {
    return <AdminUserWeb />;
  };

  const renderAdminUserTxePage = () => {
    return <AdminUserTxe />;
  };

  const renderAdminUserChPage = () => {
    return <AdminUserCh />;
  };

  const renderWorkingTimePage = () => {
    return <WorkingTime />;
  };

  const renderAdminWorkingTimePage = () => {
    return <AdminWorkingTime />;
  };

  const renderWorkingStatisticPage = () => {
    return <WorkingStatistic />;
  };

  const renderCallTrackingStatisticPage = () => {
    return <CallTrackingStatistic />;
  };

  const renderAppPhonesPage = () => {
    return <AppPhones />;
  };

  const renderSupportPage = () => {
    return <Support />;
  };

  const renderPrivacyPage = () => {
    return <Privacy />;
  };

  const renderInsurancePage = () => {
    return <Insurance />;
  };

  const renderInsuranceNearingExpirationPage = () => {
    return <InsuranceNearingExpiration />;
  };

  const renderPhapChe = () => {
    return <PhapChe />;
  };

  const renderFiles = () => {
    return <Files />;
  };

  const renderOwnerInsurancePage = () => {
    return <OwnerInsurance />;
  };

  const renderKeToanChi = () => {
    return <KeToanChi />;
  };

  const renderOwnerAssignment = () => {
    return <OwnerAssignments />;
  };

  const renderKyQuy = () => {
    return <KyQuy />;
  };

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') return;
    if (user?.id === 62) return;
    const detectDevTool = () => {
      if (devtools.isOpen && window.location.hostname === 'vicvnlogistics.com') {
        alert('Vui lòng không mở DevTools khi sử dụng phần mềm');
      }
    };
    window.addEventListener('devtoolschange', (event: any) => {
      console.log('Is DevTools open:', event.detail.isOpen);
      console.log('DevTools orientation:', event.detail.orientation);
      if (event.detail.isOpen && window.location.hostname === 'vicvnlogistics.com') {
        alert('Vui lòng không mở DevTools khi sử dụng phần mềm');
      }
    });
    window.addEventListener('load', detectDevTool);
    window.addEventListener('resize', detectDevTool);
    window.addEventListener('mousemove', detectDevTool);
    window.addEventListener('focus', detectDevTool);
    window.addEventListener('blur', detectDevTool);
  }, []);

  return (
    <div className="wrapper">
      {user.isLoggedIn() && !['/support', '/privacy'].includes(location.pathname) && <Header />}
      {!['/chat', '/support', '/privacy'].includes(location.pathname) && <NotificationBar />}
      <div id="content">
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <LoginRoute path="/login" />
            <LogoutRoute path="/logout" />
            <PrivateRoute role={CONSTANTS.SYSTEM_ROLES.QUAN_TRI_HE_THONG} path="/" exact={true} render={renderHome} />
            <PrivateRoute role="" path="/profile" render={renderUserProfile} />
            <PrivateRoute role="" path="/tai-xe" render={renderTaiXePage} />
            <PrivateRoute role="" path="/xe-dat-hang" render={renderOrderPage} />
            <PrivateRoute role="" path="/chu-hang" render={renderCustomerPage} />
            <PrivateRoute role="" path="/chu-dat-hang" render={renderOwnerPage} />
            <PrivateRoute role="" path="/van-hanh" render={renderTransportPage} />
            <PrivateRoute role="" path="/kiem-soat-dh" render={renderKiemSoatDonHangPage} />
            <PrivateRoute role="" path="/thanh-toan" render={renderPaymentPage} />
            <PrivateRoute role="" path="/don-hang-nhanh-v1" render={renderInstantOrderPage} />
            <PrivateRoute role="" path="/don-hang-nhanh" render={renderNewInstantOrderPage} />
            <PrivateRoute role="" path="/ho-so-tai-xe" render={renderDriverInfoPage} />
            <PrivateRoute role="" path="/chanh" render={renderChanhPage} />
            <PrivateRoute role="" path="/chanh-dat-hang" render={renderChanhDatHangPage} />
            <PrivateRoute role="" path="/hop-dong" render={renderReceiptPage} />
            <PrivateRoute role="" path="/bao-hiem" render={renderInsurancePage} />
            <PrivateRoute role="" path="/bao-hiem-sap-het-han" render={renderInsuranceNearingExpirationPage} />
            <PrivateRoute role="" path="/phap-che" render={renderPhapChe} />
            <PrivateRoute role="" path="/don-hang-bao-hiem" render={renderOwnerInsurancePage} />
            <PrivateRoute
              role={CONSTANTS.SYSTEM_ROLES.QUAN_TRI_HE_THONG}
              path="/tk-web"
              render={renderAdminUserWebPage}
            />
            <PrivateRoute role="" path="/admin-working-time" render={renderAdminWorkingTimePage} />
            <PrivateRoute role="" path="/thong-ke" render={renderWorkingStatisticPage} />
            <PrivateRoute role="" path="/tk-txe" render={renderAdminUserTxePage} />
            <PrivateRoute role="" path="/tk-ch" render={renderAdminUserChPage} />
            <PrivateRoute role="" path="/tk-cuoc-goi" render={renderCallTrackingStatisticPage} />
            <PrivateRoute role="" path="/working-time" render={renderWorkingTimePage} />
            <PrivateRoute role="" path="/app-phones" render={renderAppPhonesPage} />
            <PrivateRoute role="" path="/phan-viec-chu-hang" render={renderCustomerAssignPage} />
            <PrivateRoute role="" path="/phan-viec-tai-xe" render={renderDriverAssignPage} />
            <PrivateRoute role="" path="/quan-ly-nhom-chat" render={renderManageInternalChatPage} />
            <PrivateRoute role="" path="/quan-ly-file" render={renderFiles} />
            <PrivateRoute role="" path="/ke-toan-chi" render={renderKeToanChi} />
            <PrivateRoute role="" path="/tham-khao-gia" render={renderPriceQuote} />
            <PrivateRoute role="" path="/cai-dat-phan-don" render={renderOwnerAssignment} />
            <PrivateRoute role="" path="/ky-quy" render={renderKyQuy} />
            <Route path="/support" exact={true} render={renderSupportPage} />
            <Route path="/privacy" exact={true} render={renderPrivacyPage} />
            <Route path="*" render={redirectToPageNotFound} />
          </Switch>
        </Suspense>
      </div>
      {user.isLoggedIn() && !['/support', '/privacy'].includes(location.pathname) && (
        <CheckinCheckout ignorePage="working-time" />
      )}
      {user.isLoggedIn() && !['/support', '/privacy'].includes(location.pathname) && <SelectedChat />}
    </div>
  );
}
