import { ThunkResult } from '..';
import User from '../../models/user';
import { connectSocket } from '../socket/actions';
import {
  SET_CHAT_GROUPS,
  SET_NEW_CHAT_MESSAGE,
  SET_UPDATE_CHAT_MESSAGE,
  SET_SELECTED_CHAT,
  SET_UNREAD_MESSAGE_COUNT,
  SET_USER,
  // SET_INSTANTS,
  UserActionTypes,
  SET_INSTANT_SUGGESTIONS,
  SET_OWNER_ASSIGNMENTS,
  SET_MATCHING_OWNER,
  SET_MATCHING_ORDER,
  SET_NEW_ORDER,
  SET_NEW_OWNER,
  SET_NEW_PRICE_QUOTES,
  SET_INSTANT_RESERVE,
  SET_V2_INSTANTS,
} from './types';
import { getUserStorage, setAxiosHeaders, setUserStorage } from './utils';

export function initUserFromStorage(): ThunkResult<void> {
  return (dispatch) => {
    const user = getUserStorage();
    if (user) {
      if (user.token) {
        setAxiosHeaders(user.token);
      }
      dispatch(setUser(user));
    }
  };
}

export function setUser(user: any): ThunkResult<void> {
  const newUser = new User(user);
  return (dispatch) => {
    setUserStorage(newUser);
    setAxiosHeaders(newUser.token);

    if (newUser.isLoggedIn()) {
      dispatch(connectSocket());
    }

    dispatch({
      type: SET_USER,
      payload: newUser,
    });
  };
}

export function setUnreadMessageCount(count: any): UserActionTypes {
  return {
    type: SET_UNREAD_MESSAGE_COUNT,
    payload: count,
  };
}

export function setNewChatMessage(message: string): UserActionTypes {
  return {
    type: SET_NEW_CHAT_MESSAGE,
    payload: message,
  };
}

export function setUpdateChatMessage(message: string): UserActionTypes {
  return {
    type: SET_UPDATE_CHAT_MESSAGE,
    payload: message,
  };
}

export function setChatGroups(chatGroups: any[]): UserActionTypes {
  return {
    type: SET_CHAT_GROUPS,
    payload: chatGroups,
  };
}

export function setSelectedChat(chat: any): UserActionTypes {
  return {
    type: SET_SELECTED_CHAT,
    payload: chat,
  };
}

// export function setInstants(instants: any[]): UserActionTypes {
//   return {
//     type: SET_INSTANTS,
//     payload: instants,
//   };
// }

export function setV2Instants(instants: any[]): UserActionTypes {
  return {
    type: SET_V2_INSTANTS,
    payload: instants,
  };
}

export function setNewOrder(order: any): UserActionTypes {
  return {
    type: SET_NEW_ORDER,
    payload: order,
  };
}

export function setNewPriceQuotes(priceQuotes: any[]): UserActionTypes {
  return {
    type: SET_NEW_PRICE_QUOTES,
    payload: priceQuotes,
  };
}

export function setInstantReserve(reserves: any[]): UserActionTypes {
  return {
    type: SET_INSTANT_RESERVE,
    payload: reserves,
  };
}

export function setNewOwner(owner: any): UserActionTypes {
  return {
    type: SET_NEW_OWNER,
    payload: owner,
  };
}

export function setInstantSuggestions(suggestions: any[]): UserActionTypes {
  return {
    type: SET_INSTANT_SUGGESTIONS,
    payload: suggestions,
  };
}

export function setOwnerAssignments(assignments: any[]): UserActionTypes {
  return {
    type: SET_OWNER_ASSIGNMENTS,
    payload: assignments,
  };
}

export function setMatchingOwner(owner: any): UserActionTypes {
  return {
    type: SET_MATCHING_OWNER,
    payload: owner,
  };
}

export function setMatchingOrder(order: any): UserActionTypes {
  return {
    type: SET_MATCHING_ORDER,
    payload: order,
  };
}
