import { AppState } from '..';

export const userSelector = (state: AppState) => state.user.user;
export const selectedGCSelector = (state: AppState) => state.app.selectedGC;
export const selectedLocale = (state: AppState) => state.locales.locale;
export const unreadMessageCountSelector = (state: AppState) => state.user.unreadMessageCount;
export const chatGroupsSelector = (state: AppState) => state.user.chatGroups;
export const newChatMessageSelector = (state: AppState) => state.user.newChatMessage;
export const updateChatMessageSelector = (state: AppState) => state.user.updateChatMessage;
export const selectedChatSelector = (state: AppState) => state.user.selectedChat;
export const instantsSelector = (state: AppState) => state.user.instants;
export const instantV2Selector = (state: AppState) => state.user.instantsV2;
export const newOwnerSelector = (state: AppState) => state.user.newOwner;
export const newOrderSelector = (state: AppState) => state.user.newOrder;
export const newPriceQuotesSelector = (state: AppState) => state.user.newPriceQuotes;
export const instantReserveSelector = (state: AppState) => state.user.instantReserve;
export const instantSuggestionsSelector = (state: AppState) => state.user.instantSuggestions;
export const ownerAssignmentsSelector = (state: AppState) => state.user.ownerAssignments;
export const matchingOrderSelector = (state: AppState) => state.user.matchingOrder;
export const matchingOwnerSelector = (state: AppState) => state.user.matchingOwner;
